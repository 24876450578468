export class CurrencyUtils {
    static getCurrencySymbol(v: string): string {
        switch (v) {
            case 'TRY':
                return '₺';
            case 'EUR':
                return '€';
            case 'USD':
                return '$';
            case 'GBP':
                return '£';
            case 'AED':
                return 'د.إ';
            default:
                return '₺';
        }
    }
}
