import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';
import { PriceObject } from '../models/offer';
import { CurrencyUtils } from '../utils/currency.utils';
import { CurrencyService } from './currency.service';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class PriceService {
    constructor(
        private translateService: TranslateService,
        private currencyService: CurrencyService,
    ) {}

    async formatPrice(price: PriceObject, convert: boolean = false): Promise<string> {
        // Find the correct price and currency
        const sourceCurrency = price.currency || 'TRY';
        let decimalPrice = new Decimal(price.price);

        // Get the target currency
        const currency = this.currencyService.getCurrency();

        // Convert the price if needed
        if (sourceCurrency && convert) {
            decimalPrice = await this.currencyService.convert(decimalPrice, sourceCurrency);
        }

        return (
            formatNumber(decimalPrice.toNumber(), this.translateService.getLocale(), '1.2-2') +
            ' ' +
            CurrencyUtils.getCurrencySymbol(convert ? currency : sourceCurrency)
        );
    }
}
