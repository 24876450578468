import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import Decimal from 'decimal.js';
import { User } from 'src/app/models/user';
import { PriceObject } from '../../models/offer';
import { AuthService } from '../../services/auth.service';
import { ContextService } from '../../services/context.service';
import { PriceService } from '../../services/price.service';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslatePipe],
})
export class PriceComponent implements OnInit {
    displayPrice: string;
    from: boolean = false;
    blurPrice = false;
    @Input() convert: boolean = true;
    @Input() price: PriceObject;
    @Input() alwaysShowPrice: boolean = false;

    constructor(
        private readonly contextService: ContextService,
        private readonly priceService: PriceService,
        private readonly authService: AuthService,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn.subscribe((user: User) => {
            const platform = this.contextService.getPlatform();
            let price = this.price;
            if (!this.alwaysShowPrice && platform === 'wholesale' && (!user?.pro || (user?.pro && user?.status !== 'validated'))) {
                this.blurPrice = true;
                price = {
                    price: new Decimal(0),
                    currency: price?.currency || 'TRY',
                    from: price?.from || false,
                };
            } else {
                this.blurPrice = false;
            }
            this.from = price?.from || false;
            this.priceService.formatPrice(price, this.convert).then((price) => {
                this.displayPrice = price;
            });
        });
    }
}
